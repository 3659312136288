import React from 'react';
import Body from '../../../components/body/body';
import './home.css';




const Home = () => {
    
    return(

        <Body/>

    );
}
export default Home;
