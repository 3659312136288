import React from 'react';
import './footer.css';


export default class Footer extends React.Component{  

  logout = () => {
    localStorage.removeItem('token')
    window.location.reload()
  }

    render(){
      return (
        // <Router>
            // <div className="footer container text-center">
            <div className="footr">
              <span className="">&copy; All Rights Reserved, {new Date().getFullYear()}</span>
              {/* <a href={'/login'} className="faq"> Login </a> */}
              {/* <a href={TC} className="TC"> Terms and Conditions </a>
              <a href={PP} className="Policy"> Privacy Policy </a> */}
            </div>
        // </Router>
      ); 
    }
  }

