import React, { Component } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import Banner from '../Hero/Banner';
import renderHTML from 'react-render-html'

import "./service.css";

import placeholderImage from '../../assets/images/loic-leray-fCzSfVIQlVY-unsplash.jpg'



class ServiceThree extends Component {



  componentDidMount(){

  };



  render() {


    // const bannerRun = 'Our vision is to enable organisations thrive in a fast-changing world'
    // const messageOne = ``
    const messageTwo = `
    <p>The greatest risk to any organisation is its reputational risk. We provide cross-functional expertise to deliver industry focused interventions aligned with our client’s corporate values. We deliver value to businesses beyond just meeting regulatory compliance requirements. </p>
    <p>Whether it be the audit committee or the risk function within an organisation, we assist companies to develop robust enterprise risk management processes, systems and policies that safeguard their reputation, envisage future black swan events and remain competitive in a fast-changing and competitive landscape. </p>
    <br/>
    <p>Our range of services include: </p>
    <ul>
    <li>Enterprise Risk Management </li>
    <li>Conduct and Remediation</li>
    <li>Internal Audit Outsourcing (or Co-sourcing)</li>
    <li>Mental Wellness Solutions</li>
    <li>Board/Audit Committee Advisory</li>
    <li>Health, Safety and Community Engagement</li>
    </ul>`;

    return (
      <Router>

        <div>
            <Banner title = {'Risk and Strategy'} bannerRun = {''} bannerPic = {placeholderImage}/> 

            <div className='service-row'>
              <div className="col-12 col-md-7 my-auto">
                  {/* <p className="bold-service-text">{renderHTML(messageOne)}</p>
                  <hr></hr> */}
                  <p className="service-text">{renderHTML(messageTwo)}</p>
              </div>
              <div className = "col-12 col-md-5 hero-image-container">
                  {/* <div className="two"></div> */}
              </div>
            </div> 
        </div>

      </Router>
    );
  }
}

export default ServiceThree;
