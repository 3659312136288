import React, { Component } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import Banner from '../Hero/Banner';
import renderHTML from 'react-render-html'

import "./service.css";

import placeholderImage from '../../assets/images/chronis-yan-1M4wYTqVD4o-unsplash.jpg'



class ServiceTwo extends Component {



  componentDidMount(){

  };



  render() {


    // const bannerRun = 'Our vision is to enable organisations thrive in a fast-changing world'
    // const messageOne = ``
    const messageTwo = `
    <p>Experts suggest that annual investments of about 2% of global GDP is required to address climate change by 2050. We assist companies and governments to secure green finance such as climate bonds for renewable energy generation, efficient mass transportation programs, afforestation wildlife preservation schemes etc.</p>
    <p>Our professionals provide advice on regulatory compliance and accounting structuring. We leverage our strong experience in International Financial Reporting Standards (IFRSs) to deliver accounting changes and provide support on complex transactions. 
    </p>
    <p>We also assist start-ups and small businesses in raising capital (to power their next level of growth) from cornerstone investors, private investors or listing on stock exchanges.   
    </p>
    <br/>
    <p>Our range of services includes:</p>
    <ul>
    <li>Pre-IPO support</li>
    <li>Capital market advisory</li>
    <li>Raising capital from venture capital funds and private equity</li>
    <li>Mergers, Acquisition, Spin-off and Integration </li>
    <li>Due diligence</li>
    <li>Post-deal accounting</li>
    </ul>`;

    return (
      <Router>

        <div>
            <Banner title = {'Green Finance and Deal Advisory'} bannerRun = {''} bannerPic = {placeholderImage}/> 

            <div className='service-row'>
              <div className="col-12 col-md-7 my-auto">
                  {/* <p className="bold-service-text">{renderHTML(messageOne)}</p>
                  <hr></hr> */}
                  <p className="service-text">{renderHTML(messageTwo)}</p>
              </div>
              <div className = "col-12 col-md-5 hero-image-container">
                  {/* <div className="two"></div> */}
              </div>
            </div> 
        </div>

      </Router>
    );
  }
}

export default ServiceTwo;
