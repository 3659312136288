import React, { Component } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import Banner from '../Hero/Banner';
import renderHTML from 'react-render-html'

import "./service.css";

import placeholderImage from '../../assets/images/patrick-robert-doyle-yUvZYHV2Zbw-unsplash.jpg'



class ServiceFour extends Component {



  componentDidMount(){

  };



  render() {

    // const bannerRun = 'Our vision is to enable organisations thrive in a fast-changing world'
    // const messageOne = ``
    const messageTwo = `
    <p>Training: Our experienced, international faculty and thought-leadership are deployed to prepare your people to address current challenges and navigate market disruptions.</p>
    <p>Capacity development is one of the core strengths of our company. We deliver robust curricula in our training sessions by incorporating international perspectives anchored on relevant local frameworks. Our professionals have experience in providing nation-wide and international training programs for small teams or large groups across various management cadre and board of directors.</p>
    <br/>
    <ul>
    <li>Continuous Professional Development for Board of Directors</li>
    <li>Strategy/Brain-storming sessions for senior management</li>
    <li>Leadership and Teaming</li>
    <li>Diversity and Culture</li>
    <li>Innovation and Technology</li>
    </ul>`;

    return (
      <Router>

        <div>
            <Banner title = {'Training and Board Education'} bannerRun = {''} bannerPic = {placeholderImage}/> 

            <div className='service-row'>
              <div className="col-12 col-md-7 my-auto">
                  {/* <p className="bold-service-text">{renderHTML(messageOne)}</p>
                  <hr></hr> */}
                  <p className="service-text">{renderHTML(messageTwo)}</p>
              </div>
              <div className = "col-12 col-md-5 hero-image-container">
                  {/* <div className="two"></div> */}
              </div>
            </div> 
        </div>

      </Router>
    );
  }
}

export default ServiceFour;
