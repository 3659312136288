import React, { Component } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import Hero from '../../components/Hero/Hero';
import Section from '../body/Section'
import { Modal,  } from 'react-bootstrap';


import "./body.css";



class Body extends Component {


  state = {
    visibility: false,
    show: false,
    links: [],
    chosenLink:''
  };

  toggleVisibility(){
    this.setState({
      visibility: !this.state.visibility
    });
  }


  handleOpenOne = () => {
    this.setState({show: true, chosenLink: 'https://www.youtube.com/embed/0KPvKpfDkLE'})
  }

  handleOpenTwo = () => {
    this.setState({show: true, chosenLink: 'https://www.youtube.com/embed/xpt9fq1Y3bQ'})
  }

  handleOpenThree = (link) => {
    this.setState({show: true, chosenLink: 'https://www.youtube.com/embed/OAijgz_NOI8'})
  }

  handleOpenFour = (link) => {
    this.setState({show: true, chosenLink: 'https://www.youtube.com/embed/iuQ-8Zq4-Mg'})
  }

  handleOpenFive = (link) => {
    this.setState({show: true, chosenLink: 'https://www.youtube.com/embed/8NgxfnmzATU'})
  }

  handleClose = () => {
    this.setState({ show: false });
  }


  componentDidMount = () => {

  };



  render() {


    const messageOne = 'Strategy | Risk | Green Finance | Sustainability & Integrated Reporting | IFRS | Oil and Gas | Mining | Financial Services | Telecommunications, Information Technology | Communication and Entertainment'
    const messageTwo = 'Our expertise includes';

    return (
      <Router>
          {/* <header>
            <Header value={this.state.searchText} cartCount={localStorage.getItem('cart')} logonAction={this.CloseModalsignin} logoutAction={this.logout} className="App-header" />
          </header> */}
        <div>
          {/* TODO Convert Hero to Carousel, insert image above about us */}
          <Hero title = {'Our purpose is creating a sustainable world'} body = {messageOne} bodyTwo = {messageTwo} buttonText = {'Explore our services'}/> 

          <div className='linkBar' id='services'>
            
            {/* <p>SERVICES</p> */}
            <div className='newlinkBar'>
              <span className="arr">
                <p className='linkBarText'>Sustainability and Integrated Reporting</p>
                <p className='para'>We assist organisations in developing roadmaps to create sustainable businesses  by identifying sustainability risks and opportunities. From strategy to implementation, and across supply-chain to internal business operations, we deliver advisory and assurance services that enable organisations lead in sustainability reporting, embed ‘integrated thinking’ and create shared value...</p>
                <a className="btn btn-outline-secondary btn-sm" href="/service/serviceone" role="button">Read more</a> 
              </span>

              <span className="arr">
              <p className='linkBarText'>Green Finance and Deal Advisory</p>
                <p className='para'>Experts suggest that annual investments of about 2% of global GDP is required to address climate change by 2050. We assist companies and governments to secure green finance such as climate bonds for renewable energy generation, efficient mass transportation programs, afforestation wildlife preservation schemes etc. Our professionals provide advice...</p>
                <a className="btn btn-outline-secondary btn-sm" href="/service/servicetwo" role="button">Read more</a> 
              </span>

              <span className="arr">
                <p className='linkBarText'>Risk and Strategy</p>
                <p className='para'>The greatest risk to any organisation is its reputational risk. We provide cross-functional expertise to deliver industry focused interventions aligned with our client’s corporate values. We deliver value to businesses beyond just meeting regulatory compliance requirements. Whether it be the audit committee or the risk function within an organisation...</p>
                <a className="btn btn-outline-secondary btn-sm" href="/service/servicethree" role="button">Read more</a> 
              </span>

              <span className="arr">
                <p className='linkBarText'>Training and Board Education</p>
                <p className='para'>Capacity development is one of the core strengths of our company. We deliver robust curricula in our training sessions by incorporating international perspectives anchored on relevant local frameworks. Our professionals have experience in providing nation-wide and international training programs for small teams or large groups across various management cadre and board...</p>
                <a className="btn btn-outline-secondary btn-sm" href="/service/servicefour" role="button">Read more</a> 
              </span>

              <span className="arr">
                <p className='linkBarText'>Australia Africa Service</p>
                <p className='para'>Our local experience in business practices and tax regulations in Australia and across Africa make us better placed to assist you with your organisation’s strategy for Africa. Africa is the next frontier with population expected to reach 2.5 billion by 2050. More than 200 companies listed on the Australian Securities Exchange operate in Africa, with investment in mining projects in Africa...</p>
                <a className="btn btn-outline-secondary btn-sm" href="/service/servicefive" role="button">Read more</a> 
              </span>
            
            </div>

          </div>
          
          <Section linkOne= {this.handleOpenOne} linkTwo= {this.handleOpenTwo} linkThree= {this.handleOpenThree} linkFour= {this.handleOpenFour} linkFive= {this.handleOpenFive}/>

        </div>


        {/* Modal */}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton> </Modal.Header>
          <Modal.Body>
              <div className='row'>
                <div className='col-sm-12'>
            
                  <div className="section" style={{height: 'auto'}}>
                    <iframe title="brand-video" className="video" src={this.state.chosenLink} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>

                </div>
              </div>
          </Modal.Body>
        </Modal>

      </Router>
    );
  }
}

export default Body;
