import React, { Component } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import Banner from '../Hero/Banner';
import renderHTML from 'react-render-html'

import "./aboutus.css";

import placeholderImage from '../../assets/images/maarten-van-den-heuvel-8EzNkvLQosk-unsplash.jpg'


import profpic from '../../assets/images/prof_pic.jpg'
import placeOne from '../../assets/images/cover.jpeg'
import placeTwo from '../../assets/images/deakin.jpg'
import placeThree from '../../assets/images/deakinIRcenter.jpg'




class About extends Component {


  state = {}


  componentDidMount = () => {

  };



  render() {

    // <b>CEO</b><br /><br />
    const bannerRun = 'Our vision is to enable organisations thrive in a fast-changing world';
    const messageOne = `Rubicola was founded in January 2020, during the unprecedented bush-fire season in Australia which burnt an estimated 20 million hectares, killed at least 34 people and over a billion animals. Named after <i>Melomys rubicola</i>, the first Australian mammal thought to have become extinct as a result of climate change, the company’s focus is on assisting CEOs and boards to identify new and emerging risks from climate disruption while implementing strategies for sustainable business models.<br /><br />
    We assist companies and public sector entities to deliver transparent and relevant non-financial reports, mobilise finance for investment in sustainable solutions and rethink corporate strategies to address climate related risks.`;
    const messageTwo = `
    <b>Joseph Owolabi</b>
    <p>Acts as a trusted advisor to organisations in Australia, Africa and other emerging markets around the world. </p>
    <p>Joseph founded Rubicola in 2020. He had previously worked for Deloitte and PwC in Australia as an experienced director and senior manager respectively. Overseas, he has worked and done business in Africa, North America and Europe.</p>
    <p>He was the pioneer service leader for Climate Change and Sustainability Services at EY in West Africa between 2014 - 2017. Where he played key roles in the development of sustainability reporting and assurance in Nigeria. Joseph was the co-convener (along with the Global Reporting Initiative (GRI) and the Nigerian Stock Exchange (NSE)) of the Capital Markets Sustainability Conference which provided training for listed entities, blue-chip corporations and other companies and a CEO-round table session. He assisted the Federal Government of Nigeria in reporting of its Green House Gas (GHG) emissions under the Paris agreement as well as listing of the country’s first sovereign green bond – a first for emerging markets (and only second in the world). </p>
    <p>Joseph is a proven capital markets and accounting advisory leader, he directed large scale consulting projects on implementation of new accounting standards for large entities in telecoms, banking and manufacturing sectors in Australia, Africa and around the world. He assisted in completion of the Viva Energy $2.69 billion IPO, Australia’s largest in four years. </p>
    <p>Joseph has trained Board of Directors and senior management groups from over 500 companies, on enterprise risk management, accounting standards, sustainability and strategy.</p>
    <p>He served as pioneer Chairman for Australian Sickle Cell Advocacy Inc. and is a global Council member for Association of Chartered Certified Accountants (ACCA) in U.K.</p><br/>
    <p>Education</p>
    <ul>
    <li>M.A. Leading Innovation and Change; York St John’s University</li>
    <li>Executive Education programs at Harvard Business School and The Wharton School, University of Pennsylvania</li>
    </ul>`;
    const messageThree = `<b>In the news</b>
    <p>Our CEO was one of the speakers at the official launch of the Centre for Integrated Reporting <IR> at Deakin University. Professor Mervyn King, Chair Emeritus of the International Integrated Reporting Council (IIRC) delivered the keynote address. The Center's aim is making the innovative integrated reporting model the “new normal” in Australia. Click thumbnails below to read more.</p>`;

    // <p><a href = 'https://abinternational.accaglobal.com/2020/05/14/20june_int_inter_owolabi_rubicola/content.html'>https://abinternational.accaglobal.com/</a></p>
    // <p><a href = 'https://businessnewsroom.deakin.edu.au/articles/new-centre-advances-integrated-reporting-to-build-trust-in-business'>https://businessnewsroom.deakin.edu.au/articles/new-centre-advances-integrated-reporting-to-build-trust-in-business</a></p>
    // <p><a href = 'https://www.linkedin.com/feed/update/urn:li:activity:6594784481691881472/'>https://www.linkedin.com/feed/update/urn:li:activity/</a></p>


    return (
      <Router>

        <div>
            <Banner title = {'Our purpose is creating a sustainable world'} bannerRun = {bannerRun} bannerPic = {placeholderImage}/> 

            <div className='body-row'>
              <div className="col-12 col-md-7 my-auto">
                  <p className="bold-body-text">{renderHTML(messageOne)}</p>
                  <hr></hr>
                  <h5><b>CEO</b></h5> <br />
                  <div className="profileImageChip" style={{backgroundImage: `url(${profpic})`}}> </div>
                  
                  <p className="body-text">{renderHTML(messageTwo)}</p>
                  <hr></hr>
                  <p className="body-text">{renderHTML(messageThree)}</p>

                  <div className='' style={{height:'inherit', paddingTop: 20}}>
                  <a href = 'https://www.linkedin.com/feed/update/urn:li:activity:6594784481691881472/'><div className=' imageChip' style={{backgroundImage: `url(${placeTwo})`}}> </div></a>
                    {/* <div className='col imageChip' style={{backgroundImage: `url(${placeTwo})`}}> </div> */}
                    <a href = 'https://businessnewsroom.deakin.edu.au/articles/new-centre-advances-integrated-reporting-to-build-trust-in-business'><div className=' imageChip' style={{backgroundImage: `url(${placeThree})`}}> </div></a>
                    {/* <div className='col imageChip' style={{backgroundImage: `url(${placeThree})`}}> </div> */}
                    <a href = 'https://abinternational.accaglobal.com/2020/05/14/20june_int_inter_owolabi_rubicola/content.html'><div className='imageChip' style={{backgroundImage: `url(${placeOne})`, backgroundSize: `contain`}}> </div></a>
                    {/* <div className='col imageChip' style={{backgroundImage: `url(${placeOne})`, backgroundSize: `contain`}}> </div> */}
                  </div>
              </div>

              <div className = "col-12 col-md-5 hero-image-container">
                  {/* <div className="two"></div> */}
              </div>
            </div> 
        </div>

      </Router>
    );
  }
}

export default About;
