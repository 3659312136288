// import React, { Component } from 'react'; //for RCC

import React from 'react'
import './hero.css';

import slideOne from '../../assets/images/nicholas-doherty-pONBhDyOFoM-unsplash.jpg'
import slideTwo from '../../assets/images/melissa-bradley-96iwiAxOuJw-unsplash.jpg'
import slideThree from '../../assets/images/christine-von-raesfeld-DXc13fIYuXE-unsplash_copy.jpg'


//RFC - DYNAMIC VERSION USING PROPS
const Hero = ({ title, body, bodyTwo, buttonText }) => {
    return (
        // The code commented below works, but for a single static Hero banner
        // <div className = "hero-row">
        //     <div className="col-12 col-md-7 my-auto hero-text">
        //         <h1 className="hero-large">{title}</h1>
        //         <p className="hero-p-text-second">{bodyTwo}</p>
        //         <p className="hero-p-text">{body}</p>
        //         <a className="btn btn-outline-secondary my-2 my-sm-4 btn-sm" href="#services" role="button">{buttonText} </a> 
        //         {/* <FontAwesomeIcon icon={faArrowRight} style={{paddingLeft: 3}}/> */}
        //         {/* <a className="btn btn-outline-secondary my-2 my-sm-4 btn-sm" href="/signup" role="button">{buttonText}</a> */}
        //     </div>
        //     <div className = "col-12 col-md-5 hero-image-container">
        //         {/* <div className="one"></div> */}
        //         {/* <div className="two"></div> */}
        //         {/* <div className="three"></div> */}
        //     </div>
        // </div>


        <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
                <div className="carousel-item active">
                <div className = "hero-row" style={{backgroundImage: `url(${slideOne})`}}>
                    <div className="col-12 col-md-7 my-auto hero-text">
                        <h1 className="hero-large">{title}</h1>
                        <p className="hero-p-text-second">{bodyTwo}</p>
                        <p className="hero-p-text">{body}</p>
                        <a className="btn btn-outline-secondary my-2 my-sm-4 btn-sm" href="#services" role="button">{buttonText} </a> 
                    </div>
                    <div className = "col-12 col-md-5 hero-image-container">
                    </div>
                </div>
                </div>
                <div className="carousel-item">
                <div className = "hero-row" style={{backgroundImage: `url(${slideTwo})`}}>
                    <div className="col-12 col-md-7 my-auto hero-text">
                        <h1 className="hero-large">Experts suggest that annual investments of about 2% of global GDP is required to address climate change by 2050</h1>
                        <p className="hero-p-text-second"></p>
                        <p className="hero-p-text">Our professionals provide advice on regulatory compliance and accounting structuring. We leverage our strong experience in International Financial Reporting Standards (IFRSs) to deliver accounting change and support on complex transactions</p>
                        <a className="btn btn-outline-secondary my-2 my-sm-4 btn-sm" href="/service/servicetwo" role="button">{buttonText} </a> 
                    </div>
                    <div className = "col-12 col-md-5 hero-image-container">
                    </div>
                </div>
                </div>
                <div className="carousel-item">
                <div className = "hero-row" style={{backgroundImage: `url(${slideThree})`}}>
                    <div className="col-12 col-md-7 my-auto hero-text">
                        <h1 className="hero-large">We deliver robust curricula in our training sessions by incorporating international perspectives anchored on relevant local frameworks</h1>
                        <p className="hero-p-text-second"></p>
                        <p className="hero-p-text">Our professionals have experience in providing nation-wide and international training programs for small teams or large groups across various management cadre and board of directors</p>
                        <a className="btn btn-outline-secondary my-2 my-sm-4 btn-sm" href="/service/servicefour" role="button">{buttonText}</a> 
                    </div>
                    <div className = "col-12 col-md-5 hero-image-container">
                    </div>
                </div>
                </div>
            </div>
        </div>

    )
}

export default Hero;