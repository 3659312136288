import React from 'react';

import './section.css';
import placeholder from '../../assets/images/__-drz-__-CK7rdTauhDE-unsplash.jpg'
import placeholderTwo from '../../assets/images/nina-rumbines-MBUO2v9lmvA-unsplash.jpg' //markus-spiske-pl_HXNkyFmw-unsplash.jpg'
import placeholderThree from '../../assets/images/mario-gogh-VBLHICVh-lI-unsplash.jpg'


const Section = ({ linkOne, linkTwo, linkThree, linkFour, linkFive }) => {


        return (
            <div className="case" id='insights'>

                {/* <div className="hold-row section-row-two">
                    <div className="col-12 col-md-6 navy-back long-text-holder">
                        <div className="video-text" onClick={linkOne}>
                            //<a href='/'>
                            <h4 className="video-h1">INSIGHTS</h4>
                            <p className="video-p-text">Climate change: It is time for concerted efforts, investment</p>
                            //</a>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 blue-back long-text-holder">
                        <div className="video-text" onClick={linkTwo}>
                            //<a href='/'>
                            <h4 className="video-h1"  style={{color:'black'}}>INSIGHTS</h4>
                                <p className="video-p-text" style={{color:'black'}}>Green Bond Issuance: Investment Needed For Climate Resilient Economy</p>
                            //</a>
                        </div>
                    </div>
                </div> */}



                <div className="hold-row section-row">
                    {/* <a className="" href="https://www.youtube.com"> */}
                        <div className="col-12 col-md-6 section-image no-padding-only" style={{backgroundImage: `url(${placeholder})`}}>
                            {/* <a className="" href="https://www.youtube.com">
                                <div className="f1-cc--photo">
                                    <img src={videoPlaceholder} alt="" />
                                </div>
                            </a> */}
                        </div>
                    {/* </a> */}

                    <div className="col-12 col-md-6 section-image-two">

                        <div className="col-12 col-md-6 text-holder navy-back">
                            <div className="video-text" onClick={linkOne}>
                                {/* <a href='/'> */}
                                    <h4 className="video-h1">INSIGHTS</h4>
                                    {/* <button type="button" className="" onClick={linkOne}>   */}
                                        <p className="video-p-text">Climate change: It is time for concerted efforts, investment</p>
                                    {/* </button> */}
                                {/* </a> */}
                            </div>
                        </div> 


                        <div className="col-12 col-md-6 video-section no-padding">
                            <div className="thumb" style={{backgroundImage: `url(${placeholderTwo})`}}>
                            </div>
                        </div>

                        <div className="col-12 col-md-12 long-text-holder navy-back"> 
                            <div className="video-text"> {/*onClick={linkFour}>*/}
                                <a href='https://www.youtube.com/embed/iuQ-8Zq4-Mg'>
                                    <h4 className="video-h1">INSIGHTS</h4>
                                    <p className="video-p-text">Business Sustainability In Nigerian Environment</p>
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
                
                 {/* ROW 3 BELOW */}
                <div className="hold-row section-row-two">
                
                    {/* SHOWN ON DESKTOP */}
                    {/* <div className="col-12 col-md-6 text-holder blue-back desktop-only"> 
                        <div className="video-text">
                            <a href='/'>
                                <h4 className="video-h1">INSIGHTS</h4>
                                <p className="video-p-text">{title[2]}</p>
                            </a>
                        </div> 
                    </div> */}

                    <div className="col-12 col-md-6 green-back long-text-holder">
                        <div className="video-text"> {/* onClick={linkFive}>  */}
                            <a href='https://www.youtube.com/embed/8NgxfnmzATU'>
                                <h4 className="video-h1">INSIGHTS</h4>
                                <p className="video-p-text">State Of The Global Green Bond Market</p>
                            </a>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 video-section no-padding">
                        <div className="thumb" style={{backgroundImage: `url(${placeholderThree})`}}>
                        </div>
                    </div>

                </div>
            </div>
            
        )
}

export default Section;