import React, { Component } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import Banner from '../Hero/Banner';
import renderHTML from 'react-render-html'

import "./service.css";

import placeholderImage from '../../assets/images/paul-carmona-5UHgm0Q3n4Y-unsplash.jpg'



class ServiceFive extends Component {

  componentDidMount(){

  };

  render() {


    // const bannerRun = 'Our vision is to enable organisations thrive in a fast-changing world'
    const messageOne = `<em>Is your business ready to win in Africa?</em>`
    const messageTwo = `
    <p>Our local experience in business practices and tax regulations in Australia and across Africa make us better placed to assist you with your organisation’s strategy for Africa. Africa is the next frontier with population expected to reach 2.5 billion by 2050. More than 200 companies listed on the Australian Securities Exchange operate in Africa, with investment in mining projects in Africa valued at over $40 billion. We can assist you in navigating the complex business and regulatory landscape while highlighting the opportunities and challenges of doing business in Africa.</p>
    <br/>
    <p>We can support you in any of the following areas:</p>
    <ul>
    <li>Market entry and penetration strategies</li>
    <li>Recruitment and talent management</li>
    <li>Tax advisory</li>
    <li>Financial reporting and capital raising</li>
    <li>Marketing</li>
    <li>Corporate governance and anti-money laundering</li>
    </ul>`;

    return (
      <Router>

        <div>
            <Banner title = {'Australia-Africa Service'} bannerRun = {''} bannerPic = {placeholderImage}/> 

            <div className='service-row'>
              <div className="col-12 col-md-7 my-auto">
                  {/* <p className="bold-service-text">{renderHTML(messageOne)}</p> */}
                  {/* <hr></hr> */}
                  <p className="service-text">
                    <span className="bold-service-text">{renderHTML(messageOne)}</span>
                    {renderHTML(messageTwo)}
                  </p>
              </div>
              <div className = "col-12 col-md-5 hero-image-container">
                  {/* <div className="two"></div> */}
              </div>
            </div> 
        </div>

      </Router>
    );
  }
}

export default ServiceFive;
