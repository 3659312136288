import React from 'react';
import './error.css';

    
const NotFound = () => 
<div className='errorbox mx-auto'>
    <p className='errorText'>Not Found</p>
    <a href='/' className='errorButton'>Go back</a>
    {/* <button onClick={this.props.history.push('/')} className='centerButton'>
              Show more
    </button> */}
</div>

export default NotFound;
