import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Homepage from '../container/views/homepage/home';
import AboutUs from '../components/aboutus/aboutus'
import NotFound from '../container/404';
import Contact from '../components/Hero/Contact'
import Header from "../components/header/header";
import Footer from "../components/footer/footer";

import Service1 from "../components/services/serviceOne";
import Service2 from "../components/services/serviceTwo";
import Service3 from "../components/services/serviceThree";
import Service4 from "../components/services/serviceFour";
import Service5 from "../components/services/serviceFive";



export default () => (

    <div className="home">
        <BrowserRouter>
            <header>
                <Header className="App-header" />
            </header>
            <div>
                <Switch>
                    <Route exact path="/service/serviceone" component={Service1} />
                    <Route exact path="/service/servicetwo" component={Service2} />
                    <Route exact path="/service/servicethree" component={Service3} />
                    <Route exact path="/service/servicefour" component={Service4} />
                    <Route exact path="/service/servicefive" component={Service5} />

                    <Route exact path="/aboutus" component={AboutUs} />
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/" component={Homepage} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </div>
            <Footer /> 
        </BrowserRouter>
    </div>
);

