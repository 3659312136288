import React, { Component } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import Banner from '../Hero/Banner';
import renderHTML from 'react-render-html'

import "./service.css";

import placeholderImage from '../../assets/images/nina-rumbines-MBUO2v9lmvA-unsplash.jpg'



class ServiceOne extends Component {



  componentDidMount(){

  };



  render() {


    // const bannerRun = 'Our vision is to enable organisations thrive in a fast-changing world'
    // const messageOne = ``
    const messageTwo = `
    <p>We assist organisations in developing roadmaps to create sustainable businesses  by identifying sustainability risks and opportunities. From strategy to implementation, and across supply-chain to internal business operations, we deliver advisory and assurance services that enable organisations lead in sustainability reporting, embed ‘integrated thinking’ and create shared value. </p>
    <p>Companies are increasingly under public pressure concerning the appropriateness of their governance structures and  completeness of disclosures around economic and social impacts of their operations. We help our clients to articulate the value proposition for sustainability by understanding the corporate social expectations of various stakeholders – communities, regulators, investor groups and customers. </p>
    <br/>
    <ul>
    <li>Implementing integrated reporting</li>
    <li>Supply chain due diligence</li>
    <li>Assurance over sustainability and integrated reports</li>
    <li>New ventures for traditional fossil fuel companies/investors</li>
    <li>Measuring and reporting carbon foot-print</li>
    </ul>`;

    return (
      <Router>

        <div>
            <Banner title = {'Sustainability and Integrated Reporting'} bannerRun = {''} bannerPic = {placeholderImage}/> 

            <div className='service-row'>
              <div className="col-12 col-md-7 my-auto">
                  {/* <p className="bold-service-text">{renderHTML(messageOne)}</p>
                  <hr></hr> */}
                  <p className="service-text">{renderHTML(messageTwo)}</p>
              </div>
              <div className = "col-12 col-md-5 hero-image-container">
                  {/* <div className="two"></div> */}
              </div>
            </div> 
        </div>

      </Router>
    );
  }
}

export default ServiceOne;
