import React from 'react'
import './hero.css';



const Banner = ({ title, bannerRun, bannerPic }) => {
    return (
        <div className = "banner-row" style={{backgroundImage: `url(${bannerPic})`}}>
            <div className="col-12 col-md-7 my-auto">
                <p className="banner-p-text">{bannerRun}</p>
                <h1 className="banner-large">{title}</h1>
                {/* <p className="hero-p-text-second">{bodyTwo}</p> */}
            </div>
            <div className = "col-12 col-md-5 hero-image-container">
                {/* <div className="one"></div> */}
                {/* <div className="two"></div> */}
                {/* <div className="three"></div> */}
            </div>
        </div>
    )
}

export default Banner;