import React from 'react';
import './header.css';
import { BrowserRouter as Router } from "react-router-dom";

import logo from '../../assets/logos/logo.png'


// const checkRole =(x)=>{
//     if (x !== null){
//       let role = x
//       return role.split(',')[1]
//     }
//   }

  const Header = ({logonAction, logoutAction, cartCount}) => {

    // render(){

      return ( 
        <Router>

          <div>
            <nav className="navbar navbar-light">

                <div className="col-3 col-sm-3 col-md-4 col-lg-4 menu-handles">
                  {/* <h2 className=" my-auto header-title">Rubicola</h2> */}
                  <a href='/'><img src={logo} className="logo" alt="" /></a>
                </div>
  
                <span className="col-9 col-sm-9 col-md-8 col-lg-8 nav-text">
                  <li><a href='/contact'>Contact us</a></li>
                  <li><a href='/aboutus'>About us</a></li> 
                  <li><a href='/#insights'>Insights</a></li>
                  <li><a href='/#services'>Services</a></li> 
                </span>

            </nav>
          </div>

        </Router> 
      ) 
    }
  // }

  export default Header;

