import React, { Component } from 'react'; //for RCC
// import React from 'react'
import './hero.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter as Router } from "react-router-dom";
import placeholderThree from '../../assets/images/mario-gogh-VBLHICVh-lI-unsplash.jpg'


class Contact extends Component {

    componentDidMount() {

    };

    render() {
       
        return (
            <Router>
                <div className = "contact-hero-row" style={{backgroundImage: `url(${placeholderThree})`}}>
                    <div className = "col-12 col-md-7 hero-image-container">
                        {/* <div className="two"></div> */}
                    </div>
                    <div className="col-12 col-md-5 my-auto contact-text">
                        <h1 className="contact-large">Contact us</h1>
                        <div className='row'>
                            <div className='col-6'>
                                <p className="contact-hero-p-text-second">Australia</p>
                                <p className="contact-p-text">
                                    Suite 883<br></br>
                                    585 Little Collins Street<br></br>
                                    Melbourne VIC 3000<br></br>
                                    +61(0)468381308
                                </p>
                            </div>
                            <div className='col-6'>
                                <p className="contact-hero-p-text-second">Africa</p>
                                <p className="contact-p-text">
                                    4th floor, ED Building<br></br>
                                    47 Marina, Lagos<br></br>
                                    Nigeria<br></br>
                                    +234(0)8094481308
                                </p>
                            </div>
                        </div>
                        <br></br>
                        <a className="btn btn-contact my-1" href="mailto:jowolabi@rubicola.co?subject=Conference/Training inquiry" role="button">Conference/Training inquiry <FontAwesomeIcon icon={faArrowRight} style={{paddingLeft: 5, paddingTop: 3}}/></a><br></br>
                        <a className="btn btn-contact my-1" href="mailto:jowolabi@rubicola.co?subject=Media inquiry" role="button">Media inquiry <FontAwesomeIcon icon={faArrowRight} style={{paddingLeft: 5, paddingTop: 3}}/></a><br></br>
                        <a className="btn btn-contact my-1" href="mailto:jowolabi@rubicola.co?subject=Client inquiry" role="button">Client inquiry <FontAwesomeIcon icon={faArrowRight} style={{paddingLeft: 5, paddingTop: 3}}/></a>

                    </div>
                </div>
            </Router>
        )
    }
}

export default Contact;